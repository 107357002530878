import React from 'react'

export default function ContactBox() {

    
    return (
        <div className="card" key="StaunVandvaerk" >
            <div className="card-content">
                <div class="media">
                    <div class="media-content">
                        <p class="title is-4">Staun Vandværk A.m.b.a.</p>
                    </div>
                </div>

                <div class="content">
                <ul>
                    <li><a href='mailto:info@staun-vandvaerk.dk'>info@staun-vandvaerk.dk</a></li>
                    <li>Staunvej 27</li>
                    <li>9240 Nibe</li>
                    <p></p>
                    <li>CVR: 46600452</li>
                </ul>    
                </div>
            </div>
        </div>








            )
}

