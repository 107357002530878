import { graphql, useStaticQuery  } from 'gatsby'
import React from 'react'
import ContactBox from './ContactBox'

export default function ContainerPerson() {

    const data = useStaticQuery(graphql`
    query getWorkers {
        allWorkersJson {
          nodes {
            Mail
            Name
            Phonenumber
            id
            Position
            Picture
          }
        }
      }
      `)
const workers = data.allWorkersJson.nodes
    return (
        <div className="containerPersonal">
            {workers.map(worker => (
                
                <div className="card" key={worker.id} >
                    <div className="card-content">
                        <div class="media">
                            <div class="media-content">
                                <p class="title is-4">{worker.Name}</p>
                                <p class="subtitle is-6">{worker.Position}</p>
                            </div>
                        </div>

                        <div class="content">
                            <ul>
                                <li><a href={"tel:" + worker.Phonenumber}>{worker.Phonenumber}</a></li>
                                <li><a href={"mailto:" + worker.Mail}>{worker.Mail}</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                ))}
        
            <ContactBox />
            
            
            
        </div>

        
    

    )
}

