import React from 'react'
import Layout from '../components/Layout'
import ContainerPerson from '../components/ContainerPerson'

export default function contact() {
    return (
        <Layout>
            <div className="contactGrid">
                <ContainerPerson/>
            </div>
        </Layout>
    )
}
